import { Component, Prop, Emit, Vue } from "vue-property-decorator";

export class Model {}

@Component
export default class extends Vue {
  @Prop()
  public value: any;
  @Emit("onChange")
  public onChange() {
    //
  }
  @Emit("onInput")
  public onInput() {
    //
  }
}
