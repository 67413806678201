var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "textareaAutoResize" }, [
    _c("div", { staticClass: "dummy" }, [_vm._v(_vm._s(_vm.value.value))]),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value.value,
          expression: "value.value",
        },
      ],
      domProps: { value: _vm.value.value },
      on: {
        change: _vm.onChange,
        input: [
          function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.value, "value", $event.target.value)
          },
          _vm.onInput,
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }